import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Header } from "../components/typography"
import Seo from "../components/seo"
import { OutlinedButton } from "../components/buttons"
import test from "../images/test-image.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

function BlogListItem({ title, itemId, date, img, type, slug }) {
  const Image = getImage(img.localFile)
  return (
    <div
      key={itemId}
      role="button"
      tabIndex={0}
      className="block rounded-full m-3 h-full"
    >
      <Link
        to={`${slug}`}
        className="h-full flex flex-col  transition-all hover:scale-[1.02] "
      >
        <div
          className="relative"
          // style={{
          //   backgroundImage: `url(${img})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   height: "243px",
          // }}
        >
          <GatsbyImage
            className="rounded-t-3xl h-[343px] absolute inset-0"
            objectFit="cover"
            image={Image}
          />
          <OutlinedButton additionalClassName="bg-white w-fit h-fit ml-auto absolute right-5 top-5 px-3">
            <span className="md:inline-block font-primary-bold text-menublue-50 text-xs ">
              {type}
            </span>
          </OutlinedButton>
        </div>
        <div className="bg-white shadow-md rounded-b-3xl pt-2 pb-5 px-4 md:px-8  font-primary-bold grow flex flex-col justify-around">
          <p className="text-menublue-50 text-xs pb-3 font-primary-book mb-0 pt-4 lg:pt-2 lg:pb-0">
            {new Date(date).toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </p>
          <div className="flex justify-between items-center">
            <h3
              className="block text-menublue-50 font-semibold text-xl lg:text-xl w-auto font-primary-bold"
              style={{ maxWidth: "70%" }}
            >
              {title}
            </h3>
            <div
              className="flex justify-center items-center h-7 w-7 rounded-full text-menublue-50 border-2
          border-menublue-50"
            >
              <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

const Blog = ({ data, location }) => {
  const [display, setDisplay] = React.useState("all")
  //   console.log("blogs data")
  //   console.log(data)
  return (
    <Layout
      location={location}
      title="Upcoming Events"
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      fullHeader
    >
      <div className="bg-white">
        <Seo title="Discover+" />
        <div className="bg-menublue-50 h-96 flex items-center">
          <Header additionalClassName="text-center mt-12 lg:mt-20 ml-4">
            Discover+
          </Header>
        </div>
        <div
          className="relative  bg-menublue-50 md:bg-tealCustom-50 rounded-b-4xl lg:rounded-full text-white text-center
         -mt-28
                  lg:-mt-16
                  -mb-28
                  max-w-7xl
                  mx-auto
                  px-0
                  sm:px-6
                  "
        >
          <div className="py-8 ">
            <div className="flex flex-row flex-wrap items-center justify-center mb-2 sm:mb-0">
              <Link
                to={`/blog`}
                activeClassName="bg-white text-tealCustom-50"
                className="m-[4px] lg:mr-2 h-7 md:w-auto md:h-9 border-2 mb-2 border-white py-5 px-6 rounded-full text-center flex items-center font-primary-bold transition-all hover:bg-white hover:text-tealCustom-50"
              >
                All
              </Link>
              {data.allStrapiCategory.edges.map(category => {
                console.log(category.node)
                return (
                  <Link
                    to={`/blog/${category.node.slug}`}
                    activeClassName="bg-white text-tealCustom-50"
                    className="m-[4px] lg:mr-2 h-7 md:w-auto md:h-9 border-2 mb-2 border-white py-5 px-6 rounded-full text-center flex items-center font-primary-bold transition-all hover:bg-white hover:text-tealCustom-50"
                  >
                    {category.node.categoryName}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div className="bg-white mt-14 max-w-7xl mx-auto pt-28 pb-36 grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-10 p-2">
          {data.strapiCategory.blogs.map(edge => (
            <BlogListItem
              title={edge.title}
              itemId={edge.strapi_id}
              slug={`/blog/${data.strapiCategory.slug}/${edge.slug}`}
              date={edge.createdAt}
              img={edge.featuredImage}
              type={data.strapiCategory.categoryName}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const blogsQuery = graphql`
  query blogsQuery($id: String!) {
    strapiCategory(id: { eq: $id }) {
      blogs {
        id
        title
        strapi_id
        createdAt
        slug
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                placeholder: DOMINANT_COLOR
                width: 600
              )
            }
          }
        }
      }
      categoryName
      slug
    }
    allStrapiCategory {
      edges {
        node {
          id
          categoryName
          slug
        }
      }
    }
    allStrapiBlog {
      edges {
        node {
          slug
          title
          strapi_id
          createdAt
        }
      }
    }
  }
`
